<template>
  <div />
</template>

<script>
  import { refreshToken } from '../../../../utils'

  export default {
    name: 'RefreshTokensListener',
    data: () => ({
      interval: null,
      timeout: null
    }),
    mounted () {
      this.EVENT_BUS.$on('refresh-tokens:start-poll', () => {
        this.poll()
      })
      this.EVENT_BUS.$on('refresh-tokens:stop-poll', () => {
        this.kill()
      })

      window.addEventListener('mousemove', this.updateTimeout)
    },
    destroyed () {
      this.EVENT_BUS.$off('refresh-tokens:start-poll')
      this.EVENT_BUS.$off('refresh-tokens:stop-poll')

      window.removeEventListener('mousemove', this.updateTimeout)
      this.kill()
    },
    methods: {
      kill () {
        this.killInterval()
        this.killTimeout()
      },
      killInterval () {
        clearInterval(this.interval)
        this.interval = null
      },
      killTimeout () {
        clearTimeout(this.timeout)
        this.timeout = null
      },
      poll () {
        this.updateTimeout()

        this.interval = setInterval(async () => {
          const resolved = await refreshToken({ market: this.MARKET })
          if (!resolved) this.killInterval()
        }, 120000) // 2 minutes
      },
      updateTimeout () {
        if (!this.interval) return

        this.killTimeout()
        this.timeout = setTimeout(() => {
          this.killInterval()
        }, 900000) // 15 minutes
      }
    }
  }
</script>
